<template lang="">
  <q-table
    :title="this.$route.params.carid.toUpperCase()"
    dense
    :filter="filter"
    :columns="columns"
    :rows="data"
    :rows-per-page-options="[0]"
    wrap-cells
    row-key="id"
    class="my-sticky-header-table"
  >
    <template v-slot:top-right>
      <q-btn-group class="bg-white text-dark q-mr-md" push>
        <q-btn
          push
          label="New Spec"
          icon="fas fa-upload"
          @click="modal = true"
        />
      </q-btn-group>
      <q-input
        borderless
        dense
        v-model="filter"
        placeholder="Cari"
        text-color="white"
        class="q-mr-md q-px-md bg-white"
      >
        <template v-slot:append>
          <q-icon name="search" color="black" />
        </template>
      </q-input>
    </template>
    <template v-slot:body-cell-action="props">
      <q-td :props="props">
        <q-btn @click.stop="editFile(props.row.id)">Edit</q-btn>
        <q-btn
          color="red"
          class="q-mt-sm"
          icon="delete"
          @click.stop="deleteFile(props.row.id)"
        />
      </q-td>
    </template>
    <template v-slot:body-cell-part_img="props">
      <q-td :props="props" @click="openImage(props.row.part_img)">
        <img :src="props.row.part_img" alt="" srcset="" style="width: 100px" />
      </q-td>
    </template>
  </q-table>
  <q-dialog v-model="modal">
    <q-card style="width: 700px; max-width: 80vw">
      <q-card-section>
        <div class="text-h6">Upload</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <q-input label="Title" v-model="form.title" />
        <q-input label="Car Group" v-model="form.car_group" readonly />
        <q-select
          v-model="form.car_part"
          label="Category"
          :options="car_parts"
          class="bg-white q-mt-md"
          emit-value
          map-options
          required
        />
        <q-input
          v-if="form.car_part == 'spin'"
          label="URL"
          v-model="form.part_img"
        />
        <q-input label="Description" v-model="form.desc" type="textarea" />
        <input
          type="file"
          class="form-control q-mt-md"
          ref="file"
          v-on:change="onFileChange"
          required
        />
      </q-card-section>

      <q-card-actions>
        <q-btn color="green" @click="senData()">Upload</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="showImage">
    <q-card style="width: 700px; max-width: 80vw">
      <img :src="showImageUrl" alt="" srcset="" />
    </q-card>
  </q-dialog>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      data: [],
      filter: "",
      modal: false,
      showImage: false,
      showImageUrl: "",
      file: "",
      form: {
        id: "",
        car_group: this.$route.params.carid,
        car_part: "",
        title: "",
        desc: "",
        part_img: "",
      },
      car_parts: [
        {
          label: "Hero",
          value: "hero",
        },
        {
          label: "Konsep",
          value: "konsep",
        },
        {
          label: "Exterior",
          value: "exterior",
        },
        {
          label: "Interior",
          value: "interior",
        },
        {
          label: "Performa",
          value: "performa",
        },
        {
          label: "Safety",
          value: "safety",
        },
        {
          label: "Safety 2",
          value: "safety2",
        },
        {
          label: "Aksesoris",
          value: "aksesoris",
        },
        {
          label: "Spin",
          value: "spin",
        },
      ],
      columns: [
        {
          name: "car_part",
          required: true,
          label: "Part",
          align: "left",
          field: (row) => row.car_part,
          sortable: true,
        },
        {
          name: "title",
          required: true,
          label: "Title",
          align: "left",
          field: (row) => row.title,
          sortable: true,
        },
        {
          name: "desc",
          required: true,
          label: "Description",
          align: "left",
          field: (row) => row.desc,
          sortable: true,
        },
        {
          name: "part_img",
          required: true,
          label: "Part Image",
          align: "left",
        },
        {
          name: "action",
          label: "Action",
          align: "center",
          field: "",
          style: "width: 100px",
        },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      axios
        .get("/car-list/spec/" + this.$route.params.carid)
        .then((response) => {
          this.data = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    onFileChange(e) {
      this.file = e.target.files[0];
    },
    senData() {
      axios
        .post(
          "/specs",
          {
            file: this.file,
            id: this.form.id,
            car_group: this.form.car_group,
            car_part: this.form.car_part,
            title: this.form.title,
            desc: this.form.desc,
            part_img: this.form.part_img,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.getData();
          this.modal = false;
          this.file = "";
          this.form.car_part = "";
          this.form.title = "";
          this.form.desc = "";
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    editFile(id) {
      axios
        .get("/specs/edit/" + id)
        .then((response) => {
          this.modal = true;
          this.form.car_group = response.data.car_group;
          this.form.car_part = response.data.car_part;
          this.form.title = response.data.title;
          this.form.desc = response.data.desc;
          this.form.part_img = response.data.part_img;
          this.form.id = response.data.id;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    deleteFile(id) {
      this.$q
        .dialog({
          title: "Confirm",
          message: "Hapus data?",
          cancel: true,
          persistent: true,
        })
        .onOk(() => {
          axios
            .get("/specs/delete/" + id)
            .then(() => {
              this.getData();
              this.$q.notify({
                message: "Deleted Successfully",
              });
            })
            .catch(function (error) {
              console.log(error);
            });
        });
    },
    openImage(url) {
      this.showImage = true;
      this.showImageUrl = url;
    },
  },
};
</script>
<style lang=""></style>
