<template lang="">
  <div class="container">
    <div class="fit row wrap justify-center items-center content-center">
      <q-card class="my-card">
        <h5 class="q-pa-md text-center text-white"><b>Login</b></h5>
        <div class="q-pa-md" style="min-width: 400px">
          <q-form class="q-gutter-md">
            <q-input v-model="form.email" label="Email" />

            <q-input type="password" v-model="form.password" label="Password" />

            <q-toggle v-model="form.accept" label="Tetap Login" />

            <div>
              <q-btn
                label="Login"
                class="full-width"
                @click.prevent="sendData()"
                type="submit"
              />
            </div>
          </q-form>
        </div>
      </q-card>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      form: {
        accept: true,
        email: "",
        password: "",
      },
      error: "",
    };
  },
  methods: {
    sendData() {
      axios
        .post("/login", this.form)
        .then((response) => {
          if (response.data) {
            this.$router.push("/");
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("user", JSON.stringify(response.data.user));
            this.$emit("updateToken", response.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$q.notify({
              icon: null,
              message: "Login gagal.",
            });
          }
        });
    },
  },
};
</script>
<style scoped>
.my-card {
  border-radius: 20px;
}
.container {
  height: 100vh;
}

h5 {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #a10d0d;
  margin: 0;
}
</style>
