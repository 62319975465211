<template>
  <q-layout view="lHh lpr lFf" container style="height: 100vh" class="shadow-2">
    <q-header class="q-pa-sm bg-blue-9" elevated>
      <div class="row items-center">
        <q-btn
          flat
          class="q-mr-md bg-white text-black"
          label="Home"
          no-shadow
          @click="this.$router.push('/')"
        />
        <NavMenuVue />
        <q-btn
          v-if="this.$route.path !== '/'"
          class="cursor-pointer non-selectable q-ml-md bg-yellow text-dark"
          icon="fa fa-angle-double-left"
          @click="this.$router.go(-1)"
          label=" Back"
        />
        <div class="q-ml-auto">
          <q-btn
            class="q-mr-md"
            @click="$q.dark.toggle()"
            :icon="$q.dark.isActive ? 'light_mode' : 'dark_mode'"
            no-shadow
            flat
          />
          <q-btn-dropdown
            color="white"
            class="q-mr-md text-black"
            :label="user.name"
          >
            <q-list>
              <q-item clickable v-close-popup @click="onItemClick">
                <router-link to="/change-password">
                  <q-item-section>
                    <q-item-label>Change Password</q-item-label>
                  </q-item-section>
                </router-link>
              </q-item>

              <q-item clickable v-close-popup @click="logout">
                <q-item-section>
                  <q-item-label>Logout</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </div>
      </div>
    </q-header>

    <q-page-container>
      <q-page class="q-pa-sm">
        <router-view></router-view>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
import axios from "axios";
import NavMenuVue from "./NavMenu.vue";
export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  components: {
    NavMenuVue,
  },
  mounted() {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  },
  methods: {
    logout() {
      this.$q
        .dialog({
          title: "Confirm",
          message: "Logout?",
          cancel: true,
          persistent: true,
        })
        .onOk(() => {
          localStorage.clear();
          this.$emit("updateToken", null);
        });
    },
  },
};
</script>

<style scoped>
/* .q-item {
  background-color: #0d47a1;
  margin: 5px 0px 5px 15px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  color: #f4f6f9;
  font-weight: bold;
}

.router-link-active .q-item {
  color: #0d47a1;
  background-color: #f4f6f9 !important;
} */
</style>
