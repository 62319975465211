<template lang="">
  <q-btn-group class="bg-white text-black">
    <router-link to="/">
      <q-btn class="cursor-pointer non-selectable"> News </q-btn>
    </router-link>
    <router-link to="/">
      <q-btn class="cursor-pointer non-selectable"> Promo </q-btn>
    </router-link>
  </q-btn-group>
</template>
<script>
export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
};
</script>
<style lang=""></style>
