<template>
  <div v-if="token">
    <MainLayout @updateToken="updateToken" />
  </div>
  <div v-else>
    <LoginView @updateToken="updateToken" />
  </div>
</template>
<script>
import MainLayout from "./layout/MainLayout.vue";
import LoginView from "./views/LoginView.vue";
export default {
  data() {
    return {
      token: localStorage.getItem("token"),
    };
  },
  components: {
    MainLayout,
    LoginView,
  },
  methods: {
    updateToken(token) {
      this.token = token;
    },
  },
};
</script>
<style></style>
