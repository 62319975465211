import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import axios from "axios";
import print from "vue3-print-nb";
import JsonCSV from "vue-json-csv";

axios.defaults.baseURL = "https://mitsubishi-kaltimkaltara.com/api/";
// axios.defaults.baseURL = "http://127.0.0.1:8000/api/";

import "./assets/main.css";

let app = createApp(App).use(Quasar, quasarUserOptions);

app.use(router);
app.component("downloadCsv", JsonCSV);
app.use(Quasar, quasarUserOptions);
app.use(print);
app.mount("#app");
