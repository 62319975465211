<template lang="">
  <q-table
    title="Master Cars"
    dense
    :filter="filter"
    :rows="data"
    :rows-per-page-options="[0]"
    :columns="columns"
    @row-click="onRowClick"
    row-key="id"
    class="my-sticky-header-table"
  >
    <template v-slot:top-right>
      <q-btn-group class="bg-white text-dark q-mr-md" push>
        <q-btn
          push
          label="New Car"
          icon="fas fa-upload"
          @click="modal = true"
        />
      </q-btn-group>
      <q-input
        borderless
        dense
        v-model="filter"
        placeholder="Cari"
        text-color="white"
        class="q-mr-md q-px-md bg-white"
      >
        <template v-slot:append>
          <q-icon name="search" color="black" />
        </template>
      </q-input>
    </template>
    <template v-slot:body-cell-img="props">
      <q-td :props="props">
        <img :src="props.row.img" alt="" srcset="" />
      </q-td>
    </template>
    <template v-slot:body-cell-url="props">
      <q-td :props="props">
        <a :href="props.row.url" target="_blank">{{ props.row.url }}</a>
      </q-td>
    </template>
    <template v-slot:body-cell-action="props">
      <q-td :props="props">
        <q-btn @click.stop="editFile(props.row.id)" class="q-mr-sm">Edit</q-btn>
        <q-btn color="red" @click.stop="deleteFile(props.row.id)">Hapus</q-btn>
      </q-td>
    </template>
  </q-table>
  <q-dialog v-model="modal">
    <q-card style="width: 700px; max-width: 80vw">
      <q-card-section>
        <div class="text-h6">Upload</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <q-input label="Name" v-model="form.name" />
        <q-input label="Group" v-model="form.group" />
        <q-input label="Link" v-model="form.link" />
        <input
          type="file"
          class="form-control q-mt-md"
          ref="file"
          v-on:change="onFileChange"
          required
        />
      </q-card-section>

      <q-card-actions>
        <q-btn color="green" @click="uploadFile()">Upload</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import axios from "axios";
import moment from "moment/dist/moment.js";
import "moment/dist/locale/id";
moment.locale("id");
export default {
  data() {
    return {
      data: [],
      modal: false,
      file: "",
      form: {
        group: "",
        link: "",
        name: "",
      },
      filter: "",
      columns: [
        {
          name: "img",
          required: true,
          label: "Image",
          align: "left",
          style: "width: 210px",
        },
        {
          name: "name",
          required: true,
          label: "Name",
          align: "left",
          field: (row) => row.name,
          sortable: true,
        },
        {
          name: "car-unique-id",
          required: true,
          label: "Car Unique ID",
          align: "left",
          field: (row) => row.group,
          sortable: true,
        },
        {
          name: "stat",
          required: true,
          label: "Status",
          align: "left",
          field: (row) => row.stat,
          sortable: true,
        },
        {
          name: "action",
          label: "Action",
          field: "",
          style: "width: 60px",
        },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      axios
        .get("/car-list")
        .then((response) => {
          this.data = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    onRowClick(evt, row) {
      this.$router.push("/car-spec/" + row.group);
    },
    onFileChange(e) {
      this.file = e.target.files[0];
    },
    uploadFile() {
      axios
        .post(
          "/car-list",
          {
            file: this.file,
            name: this.form.name,
            group: this.form.group,
            link: this.form.link,
            id: this.form.id,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.modal = false;
          this.getData();
          this.file = "";
          this.name = "";
          this.category = "";
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    editFile(id) {
      axios
        .get("/car-list/edit/" + id)
        .then((response) => {
          this.modal = true;
          this.form.name = response.data.name;
          this.form.group = response.data.group;
          this.form.link = response.data.link;
          this.form.id = response.data.id;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    deleteFile(id) {
      this.$q
        .dialog({
          title: "Confirm",
          message: "Hapus data?",
          cancel: true,
          persistent: true,
        })
        .onOk(() => {
          axios
            .get("/car-list/delete/" + id)
            .then(() => {
              this.getData();
              this.$q.notify({
                message: "Deleted Successfully",
              });
            })
            .catch(function (error) {
              console.log(error);
            });
        });
    },
  },
};
</script>
<style scoped>
td img {
  max-width: 200px;
  border-radius: 10px;
}
</style>
